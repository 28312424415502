import { type ArgumentsType } from 'vitest';

import { type EnumsPageName } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { EmbeddedPublicLibrary } from '../../pages/GamePack';
import { GamePackContextProvider } from '../../pages/GamePack/Context';
import { type GamePack } from '../../types/game';
import { EnterExitTailwindTransition } from '../common/TailwindTransition';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { GameCenterContextProvider } from '../Game/GameCenter';
import { GameLibraryProvider } from '../Game/GameLibrary';
import { XIcon } from '../icons/XIcon';

function Library(props: {
  pageName?: EnumsPageName;
  onClickGamePack: (item: GamePack) => void;
  onClickClose?: () => void;
  initialRoute?: ArgumentsType<typeof EmbeddedPublicLibrary>[0]['initialRoute'];
}) {
  return (
    <EnterExitTailwindTransition
      initialClasses='scale-90 opacity-0'
      enteredClasses='scale-100 opacity-100'
    >
      {(ref, initial) => (
        <div
          ref={ref}
          className={`bg-black bg-opacity-80 border border-black-001 flex flex-col z-50 absolute inset-4 transform transition-all ${initial}`}
        >
          <button
            type='button'
            className='
          absolute top-0 right-0 z-50 icon-btn-no-shadow bg-transparent w-10 h-10 text-white rounded-none
        '
            onClick={props.onClickClose}
          >
            <XIcon />
          </button>

          <EmbeddedPublicLibrary
            pageName={props.pageName}
            initialRoute={props.initialRoute}
            onGamePackClick={props.onClickGamePack}
          />
        </div>
      )}
    </EnterExitTailwindTransition>
  );
}

export function GamePackPicker(props: {
  pageName?: EnumsPageName;
  onCancel: () => void;
  onSelect: (gamePack: GamePack) => void;
  initialRoute?: ArgumentsType<typeof EmbeddedPublicLibrary>[0]['initialRoute'];
}): JSX.Element {
  return (
    <GameCenterContextProvider>
      <GamePackContextProvider
        embed={true}
        pageType='public'
        noOnboardingTasks
        noPlayButton
      >
        <GameLibraryProvider>
          <Library
            pageName={props.pageName}
            initialRoute={props.initialRoute}
            onClickClose={props.onCancel}
            onClickGamePack={(gamePack) => {
              props.onSelect(gamePack);
            }}
          />
        </GameLibraryProvider>
      </GamePackContextProvider>
    </GameCenterContextProvider>
  );
}

export function useTriggerGamePackPicker() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(
    async (props: {
      pageName?: EnumsPageName;
      onSelect: (pack: GamePack) => void;
      onCancel?: () => void;
    }) => {
      await triggerModal({
        kind: 'custom',
        containerClassName: 'bg-none',
        element: (p) => (
          <div className='fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center'>
            <div
              className={`w-3/4 border border-secondary rounded-xl px-20 py-12 bg-modal`}
            >
              <GamePackPicker
                pageName={props.pageName}
                onCancel={() => {
                  props.onCancel?.();
                  p.internalOnCancel();
                }}
                onSelect={async (pack) => {
                  props.onSelect(pack);
                  p.internalOnConfirm();
                }}
              />
            </div>
          </div>
        ),
      });
    }
  );
}
